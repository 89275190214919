import {
  Currency,
  ExploreEvent,
  ExploreProgram,
  TrafficSource,
  Venue,
} from '@citruscamps/citrus-client'
import { faCalendarAlt, faCalendarWeek, faClock } from '@fortawesome/pro-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime, Interval } from 'luxon'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { Blurhash } from 'react-blurhash'
import { ROUTES } from '../../constants/routes'
import { useFetchTrafficSource } from '../../hooks/useFetchTrafficSource'
import { Address } from '../../utils/address'
import { getProgramCardImageStyle } from '../../utils/branding-utils'
import { formatList } from '../../utils/formatters'
import { useFlags } from '../FeatureFlag/hooks/useFlags'
import { ProgramBanner } from '../ProgramCard/ProgramCard'
import { TruncatedText } from '../TruncatedText/TruncatedText'

const parseDate = (date: string | Date, timezone?: string): DateTime | undefined =>
  date && date instanceof Date
    ? DateTime.fromJSDate(date, {
        zone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    : date && typeof date === 'string'
    ? DateTime.fromJSDate(new Date(date), {
        zone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    : undefined

interface IProps {
  event: ExploreEvent
  program: ExploreProgram
  size?: 'sm' | 'md'
  focused?: boolean
  unfocused?: boolean
  openInNewTab?: boolean
  showDetails?: boolean
  trafficSource?: TrafficSource
  onClick?: (event: ExploreEvent) => Promise<void> | void
}

export const EventCard = (props: IProps) => {
  const { event, program, trafficSource, onClick: handleClick, ...restProps } = props
  const { headers: _, ...trafficSourceParams } = trafficSource || {}
  const { query } = useRouter()
  const { data: originalTrafficSource } = useFetchTrafficSource()
  if (typeof handleClick !== 'undefined') {
    return (
      <EventCardContent
        event={event}
        program={program}
        trafficSource={trafficSource}
        {...restProps}
        onClick={() => {
          handleClick?.(event)
        }}
      />
    )
  }
  return (
    <Link
      href={{
        pathname: ROUTES.EVENT_DETAILS,
        query: {
          ...(query.order_id ? { order_id: query.order_id } : {}),
          program_id: program.id,
          event_id: event.id,
          ...(!originalTrafficSource ? trafficSourceParams || {} : {}),
        },
      }}
      className={`event-link ${
        event.order_availability.is_sold_out ? 'sold-out' : 'has-capacity'
      } text-dark text-decoration-none`}
      target={props?.openInNewTab ? '_blank' : undefined}
      rel="noopener noreferrer"
      passHref
    >
      <EventCardContent
        event={event}
        program={program}
        trafficSource={trafficSource}
        {...restProps}
      />
    </Link>
  )
}

interface IPriceTagProps {
  event: ExploreEvent
  program: ExploreProgram
}

const PriceTag = ({ event, program }: IPriceTagProps) => {
  const { flags } = useFlags()
  const lowestOption: Currency | undefined = event.order_availability.minimum_option_price
  const isSoldOut: boolean = !!event.order_availability?.is_sold_out
  const lowestPrice: string | undefined = isSoldOut
    ? program.preferences?.sold_out_text || program.preferences?.sold_out_text || 'Sold out'
    : event.restrict_to_package_ids?.length > 0 && flags?.memberships_enabled
    ? 'Members only'
    : event.preregistration_enabled
    ? 'Preregister'
    : lowestOption?.value && lowestOption.display
    ? (lowestOption.display.split('.').shift() || '').trim()
    : typeof lowestOption !== 'undefined' && lowestOption?.value === 0
    ? 'Free'
    : undefined
  if (!lowestPrice) return null
  return (
    <div
      className="price-tag rounded p-2 mr-2 mt-2 text-center position-absolute top-0 right-0 shadow-sm"
      style={{ background: 'white', minWidth: 50 }}
    >
      {lowestPrice}
    </div>
  )
}

type IEventCardContentProps = IProps &
  Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'size'>

const EventCardContent = ({
  event,
  program,
  size = 'md',
  focused,
  unfocused,
  openInNewTab,
  showDetails,
  trafficSource,
  ...cardProps
}: IEventCardContentProps) => {
  const cardImgTop = useRef<HTMLDivElement>(null)
  const isSoldOut: boolean = !!event.order_availability?.is_sold_out
  const address: Venue | undefined = event.venues?.[0]
  const isFocused: boolean = !((isSoldOut || unfocused) && !focused)
  return (
    <div
      className="card event-card shadow border-0"
      style={{ borderRadius: '1rem', cursor: 'pointer' }}
      {...cardProps}
    >
      <div ref={cardImgTop} className={`card-img-top${size === 'sm' ? ' hidden' : ''}`}>
        <div
          className="card-img"
          style={{
            // Fixes weird bug with rounded corners
            transform: 'scale(1.01)',
            ...getProgramCardImageStyle(program?.preferences),
          }}
        >
          {!!event.picture?.blurhash && isFocused && (
            <Blurhash
              hash={event.picture.blurhash}
              width={cardImgTop.current?.offsetWidth}
              height={cardImgTop.current?.offsetHeight}
              resolutionX={32}
              resolutionY={32}
              punch={1}
              className="position-absolute top-0 left-0"
            />
          )}
          <EventBanner
            event={event}
            program={program}
            sizes="(max-width: 768px) 100vw, (max-width: 992px) 50vw, 33vw"
            isFocused={isFocused}
          />
        </div>
        <PriceTag event={event} program={program} />
      </div>
      <div className={`card-body${(isSoldOut || unfocused) && !focused ? ' text-muted' : ''}`}>
        <div className="row">
          <div className={`${size === 'sm' ? 'col' : 'col-4 col-sm-3'} text-center`}>
            <EventCardDate event={event} />
          </div>
          <div
            className={`col-8 col-sm-9${size === 'sm' ? ' d-none d-sm-block' : ''}`}
            style={{ minHeight: showDetails ? '10rem' : '8rem' }}
          >
            <TruncatedText lines={3} className="h5 card-title mb-2">
              {event.name}
            </TruncatedText>
            {!!showDetails && (
              <TruncatedText
                className="text-muted"
                style={{ marginTop: '-0.25rem', marginBottom: '0.5rem' }}
              >
                {program.name}
              </TruncatedText>
            )}
            <EventCardDateTimeInfo event={event} />
            {!!showDetails && address && (
              <div className="row no-gutters">
                <div className="col-2">
                  <FontAwesomeIcon fixedWidth={true} icon={faMapMarkerAlt} />
                </div>
                <TruncatedText className="col-10">
                  {Address.fromObject(address).toLocaleString(Address.CITY_REGION_SHORT)}
                </TruncatedText>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface IBannnerProps
  extends Pick<IProps, 'event' | 'program'>,
    React.HTMLAttributes<HTMLDivElement> {
  isFocused?: boolean
  objectFit?: 'contain' | 'fill'
  sizes: string
}

export const EventBanner = ({
  event,
  program,
  sizes,
  objectFit = 'contain',
  isFocused = true,
  ...props
}: IBannnerProps) => (
  <div {...props}>
    {!event.picture_url && (
      <ProgramBanner
        program={program}
        sizes={sizes}
        style={{
          opacity: !isFocused ? 0.6 : undefined,
        }}
      />
    )}
    {!!event.picture_url && (
      <Image
        alt={event?.name}
        src={event.picture_url}
        fill
        sizes={sizes}
        style={{
          objectFit,
          opacity: !isFocused ? 0.6 : undefined,
        }}
      />
    )}
  </div>
)

interface IEventCardDateProps {
  event: ExploreEvent
}

const EventCardDate = ({ event }: IEventCardDateProps) => {
  const { query } = useRouter()
  const startsAt: DateTime | undefined = parseDate(event.starts_at, event.timezone)
  const dateDisplay: DateTime | undefined =
    !Array.isArray(query.scheduled_gte) && query.scheduled_gte
      ? parseDate(query.scheduled_gte, event.timezone)
      : undefined
  if (dateDisplay) {
    return (
      <>
        <p className="text-primary text-uppercase mb-0">{dateDisplay.toFormat('LLL')}</p>
        <p className="mb-0 h4">{dateDisplay.toFormat('d')}</p>
        <p className="text-muted small">{dateDisplay.toFormat('ccc').toUpperCase()}</p>
      </>
    )
  } else if (startsAt && startsAt.isValid) {
    return (
      <>
        <p className="text-primary text-uppercase mb-0">{startsAt.toFormat('LLL')}</p>
        <p className="mb-0 h4">{startsAt.toFormat('d')}</p>
        <p className="text-muted small">{startsAt.toFormat('ccc').toUpperCase()}</p>
      </>
    )
  } else {
    return null
  }
}

const EventCardDateTimeInfo = ({ event }: IEventCardDateProps) => {
  const interval = Interval.fromDateTimes(
    DateTime.fromJSDate(event.starts_at),
    DateTime.fromJSDate(event.ends_at),
  )

  if (event.is_series) {
    const isWithinMonth = interval.toDuration('months').months <= 1
    const formatOpts: Intl.DateTimeFormatOptions = isWithinMonth
      ? DateTime.DATE_MED
      : {
          year: 'numeric',
          month: 'short',
        }
    const daysOfWeek =
      event.series_dates
        ?.sort(
          (a, b) =>
            DateTime.fromJSDate(a.starts_at).weekday - DateTime.fromJSDate(b.starts_at).weekday,
        )
        ?.map(
          (series) =>
            `${DateTime.fromJSDate(series.starts_at).toLocaleString({
              weekday: 'long',
            })}s`,
        )
        .reduce<string[]>((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []) || []
    const isMultiDays = daysOfWeek.length > 2
    const times =
      event.series_dates
        ?.sort(
          (a, b) =>
            DateTime.fromJSDate(a.starts_at).toMillis() -
            DateTime.fromJSDate(b.starts_at).toMillis(),
        )
        ?.map((series) =>
          Interval.fromDateTimes(
            DateTime.fromJSDate(series.starts_at),
            DateTime.fromJSDate(series.ends_at),
          ).toLocaleString(DateTime.TIME_SIMPLE),
        )
        .reduce<string[]>((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []) || []
    const isMultiTimes = times.length > 1
    return (
      <>
        <div className="row no-gutters">
          <div className="col-2">
            <FontAwesomeIcon fixedWidth={true} icon={faCalendarWeek} />
          </div>
          <div className="col-10">
            <TruncatedText>{interval.toLocaleString(formatOpts)}</TruncatedText>
          </div>
        </div>
        {!isMultiTimes && (
          <div className="row no-gutters">
            <div className="col-2">
              <FontAwesomeIcon fixedWidth={true} icon={faClock} />
            </div>
            <TruncatedText className="col-10">{formatList(times, 'and')}</TruncatedText>
          </div>
        )}
        {isMultiTimes && !isMultiDays && (
          <div className="row no-gutters">
            <TruncatedText className="col-10 offset-2">{formatList(daysOfWeek)}</TruncatedText>
          </div>
        )}
        {isMultiTimes && isMultiDays && (
          <div className="row no-gutters">
            <div className="col-2">
              <FontAwesomeIcon fixedWidth={true} icon={faClock} />
            </div>
            <TruncatedText className="col-10">Various times</TruncatedText>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div className="row no-gutters">
        <div className="col-2">
          <FontAwesomeIcon fixedWidth={true} icon={faCalendarAlt} />
        </div>
        <TruncatedText className="col-10">
          {interval.toLocaleString({
            ...DateTime.DATE_MED,
            timeStyle: undefined,
          })}
        </TruncatedText>
      </div>
      <div className="row no-gutters">
        <div className="col-2">
          <FontAwesomeIcon fixedWidth={true} icon={faClock} />
        </div>
        <TruncatedText className="col-10">{interval.toFormat('t')}</TruncatedText>
      </div>
    </>
  )
}
